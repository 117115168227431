<template>
  <div>
    <v-card class="pr-5 mb-5 pb-3">
      <v-card-title class="d-flex">
        <h5>{{ sectionLabel }} No. {{ typeId }} Activities</h5>
      </v-card-title>

      <v-timeline align-top dense>
        <v-timeline-item small v-for="item in auditLogs.audits" :key="item.id">
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{ formatDate(item.createdAt) }}</strong>
            </v-col>
            <v-col>
              <div class="text-caption">{{ item.user_id }}</div>
              <strong>{{ item.action }}</strong>
            </v-col>
            <v-col>
              <json-viewer
                v-if="!isNullOrUndefined(item.changes)"
                :value="item.changes"
                :expand-depth="0"
                boxed
                sort
                copyable
              ></json-viewer>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>

      <v-pagination
        v-model="page"
        @next="fetchAuditLogs"
        @previous="fetchAuditLogs"
        :length="totalPages"
      ></v-pagination>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';
import JsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';
import { helpers } from '@/mixins/helpers';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'InvoiceActivitiesTable',
  components: {
    JsonViewer,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
  },
  mixins: [helpers],
  data() {
    return {
      itemsPerPage: 10,
      fetchAuditLogsLoading: false,
      page: 1,
      auditLogs: {
        audits: [],
        count: 0,
      },
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    sectionLabel() {
      return this.type === 'invoice' ? 'Invoice' : 'Payment Plan';
    },
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Action',
          value: 'action',
          ...defaults,
        },
        {
          text: 'User',
          value: 'user_id',
          ...defaults,
        },
        {
          text: 'Changes',
          value: 'changes',
          ...defaults,
        },
        {
          text: 'Date',
          value: 'createdAt',
          ...defaults,
        },
      ];
    },
    totalPages() {
      return Math.ceil(this.auditLogs.count / this.itemsPerPage);
    },
  },
  mounted() {
    this.fetchAuditLogs();
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('MMMM Do YYYY HH:mm:ss');
    },
    async fetchAuditLogs() {
      try {
        this.fetchAuditLogsLoading = true;
        let customer_id = this.customer.customer_id;
        if (customer_id === 0) {
          customer_id = this.selectedCustomer.customer_id;
        }
        let url = `/proxy/invoice-audit-logs`;
        url += `?customer_id=${customer_id}&entityType=${this.type}`;
        url += `&patient_id=${this.patientId}&page=${this.page}&limit=${this.itemsPerPage}`;
        url += `&entityId=${this.typeId}`;

        const response = await window.axios.get(url);
        if (response && response.data.success) {
          this.auditLogs = response.data.result;
        } else {
          throw response.data;
        }
      } catch (error) {
        this.$store.dispatch('notifications/setError', error.message, {
          root: true,
        });
      } finally {
        this.fetchAuditLogsLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Plans {
  padding-top: 20px;

  &__filter-row {
    padding: 15px 0;
  }

  &__clickable {
    cursor: pointer;
  }
}
.emptybackground {
  background-color: white;
}
</style>
