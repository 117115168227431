<template>
  <div>
    <v-row justify="space-between" class="mb-2 ml-2">
      <v-col cols="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined dense>
              <v-icon left>mdi-filter</v-icon>
              Status {{ getStatusText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="status in statuses" :key="status">
              <v-list-item-content @click="updateStatus(status)">
                {{ status }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      item-key="id"
      class="Plans__clickable"
      show-expand
      :headers="headers"
      :items="batches"
      :loading="fetchPaymentPlanLoading || toggleBatchStatusLoading"
      :single-expand="true"
      :expanded.sync="expanded"
      @click:row="updatePaymentPlan"
      :server-items-length="paymentPlansTotal"
      :footer-props="{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions,
      }"
      @update:options="onPlanPageChange"
    >
      <template v-slot:item.next_payment_date="{ item }">
        <div>
          {{ formatDate(item.next_payment_date) }}
        </div>
      </template>

      <template v-slot:item.last_payment_date="{ item }">
        <div>
          {{ formatDate(item.last_payment_date) }}
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status === 'active'"
          color="green"
          outlined
          text-color="green"
        >
          ACTIVE
        </v-chip>
        <v-chip
          v-else-if="item.status === 'disabled'"
          color="blue"
          outlined
          text-color="blue"
        >
          DISABLED
        </v-chip>
        <v-chip
          v-else-if="item.status === 'cancelled'"
          color="red"
          outlined
          text-color="red"
        >
          CANCELLED
        </v-chip>
        <v-chip
          v-else-if="item.status === 'paid'"
          color="orange"
          outlined
          text-color="orange"
        >
          PAID
        </v-chip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="emptybackground">
          <v-card class="p-2 mb-2">
            <v-data-table
              :headers="invoiceHeaders"
              :items="item.invoices"
              item-key="id"
              :hide-default-footer="true"
              :items-per-page="-1"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  v-if="item.charges.every((charge) => charge.statusIsPaid)"
                  outlined
                  color="green"
                  text-color="green"
                >
                  PAID
                </v-chip>
                <v-chip v-else outlined color="red" text-color="red">
                  UNPAID
                </v-chip>
              </template>
              <template v-slot:item.patient_balance="{ item }">
                {{
                  item.charges
                    .reduce((a, c) => {
                      if (!c.statusIsPaid) {
                        a += c.patient_balance;
                      }
                      return a;
                    }, 0.0)
                    .toFixed(2)
                }}
              </template>
              <template v-slot:item.total_charge="{ item }">
                {{
                  item.charges
                    .reduce((a, c) => {
                      return (
                        a +
                        c.total_charge -
                        c.insurance_paid -
                        c.write_off_amount
                      );
                    }, 0.0)
                    .toFixed(2)
                }}
              </template>
            </v-data-table>
          </v-card>

          <InvoiceActivitiesTable
            :patientId="patientId"
            type="autopayBatch"
            :typeId="item.id"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';
import InvoiceActivitiesTable from './invoice-activity-table.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'PaymentPlansTable',
  components: {
    InvoiceActivitiesTable,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
    toggleScheduleModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      expanded: [],
      paymentPlanCurrentPage: 1,
      paymentPlanCurrentItemsPerPage: 10,
      dialog: false,
      status: 'All',
      statuses: ['All', 'Active', 'Disabled', 'Paid', 'Cancelled'],
      itemsPerPageOptions: [10, 20, 50, 100],
    };
  },
  computed: {
    ...invoiceGetters({
      paymentPlans: 'PAYMENT_PLANS',
      paymentPlansTotal: 'PAYMENT_PLANS_TOTAL',
      fetchPaymentPlanLoading: 'FETCH_PAYMENT_PLAN_LOADING',
      toggleBatchStatusLoading: 'TOGGLE_BATCH_STATUS_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    getStatusText() {
      return this.status;
    },
    batches() {
      return this.paymentPlans;
    },
    invoiceHeaders() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Invoice id',
          value: 'id',
          ...defaults,
        },
        {
          text: 'Total charge (USD)',
          value: 'total_charge',
          ...defaults,
        },
        {
          text: 'Remaining balance (USD)',
          value: 'patient_balance',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
      ];
    },
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Total charge (USD)',
          value: 'balance',
          ...defaults,
        },
        {
          text: 'Montly Payment',
          value: 'regular_payment_amount',
          ...defaults,
        },
        {
          text: 'Last Payment Date',
          value: 'last_payment_date',
          ...defaults,
        },
        {
          text: 'Next Payment Date',
          value: 'next_payment_date',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
      ];
    },
  },
  methods: {
    ...invoiceActions(['fetchPaymentPlans', 'toggleBatchStatus']),
    onPlanPageChange(options) {
      this.paymentPlanCurrentPage = options.page;
      this.paymentPlanCurrentItemsPerPage = options.itemsPerPage;
      this.getPaymentPlans();
    },
    updatePaymentPlan(selectedPlan) {
      this.toggleScheduleModal({ selectedPaymentPlan: selectedPlan });
    },
    updateStatus(status) {
      this.status = status;
      this.getPaymentPlans();
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('YYYY-MM-DD');
    },
    async getPaymentPlans() {
      await Promise.all([
        this.fetchPaymentPlans({
          search: this.patientId,
          status:
            this.status.toLowerCase() === 'all'
              ? null
              : this.status.toLowerCase(),
          limit: this.paymentPlanCurrentItemsPerPage,
          page: this.paymentPlanCurrentPage,
        }),
      ]);
    },
    async refreshPage() {
      await this.getPaymentPlans();
    },
    async toggleBatch(batchId) {
      await this.toggleBatchStatus(batchId);
      await this.getPaymentPlans();
    },
  },
};
</script>
<style scoped lang="scss">
.Plans {
  padding-top: 20px;

  &__filter-row {
    padding: 15px 0;
  }

  &__clickable {
    cursor: pointer;
  }
}
.emptybackground {
  background-color: white;
}
</style>
